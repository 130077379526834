import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdmin = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      {currentUser?.data?.role?.slug === 'admin-pusat' ? (
        <SidebarMenuItem
          to='/apps/admin-pusat-dashboard/dashboard'
          title='Dashboard'
          hasBullet={true}
        />
      ) : (
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/bi-management'
        title='Classification'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen051.svg'
      >
        <SidebarMenuItem
          to='/apps/bi-management/top-programs'
          title='Winning Programs'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/bi-management/trend-programs'
          title={`Trend Programs`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/bi-management/platinum-donors'
          title='Platinum Donors'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Projections'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen051.svg'
      >
        <SidebarMenuItem
          to='/apps/bi-management/sales-projections'
          title='Sales'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/customer-management/customer'
          title={`Programs`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/prospect-management/prospect'
          title='Getting Promotions'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/prospect-management/prospect'
          title='Rewards'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Sentiment'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen051.svg'
      >
        <SidebarMenuItem to='/apps/bi-management/sentiment-sales' title='Sales' hasBullet={true} />
        <SidebarMenuItem
          to='/apps/bi-management/sentiment-sales'
          title={`Brand`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/bi-management/sentiment-sales'
          title='Product'
          hasBullet={true}
        />
        <SidebarMenuItem to='/apps/bi-management/sentiment-sales' title='People' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Generative AI'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen051.svg'
      >
        <SidebarMenuItem to='/apps/crm/channel-management/channel' title='Sales' hasBullet={true} />
        <SidebarMenuItem
          to='/apps/crm/customer-management/customer'
          title={`Summarization`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainAdmin
