import {useNavigate} from 'react-router-dom'
import {useBiStore} from '../states/bi.state'
import {useEffect} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {LoadingComponent} from '../../apps/crm/properties-management/components/loading.component'
import ReactPaginate from 'react-paginate'
import {generateCurrencyFormat} from '../../../helpers/generate-currency-format'
import {generateImageUrl} from '../../../../_metronic/helpers/generate-image-url'

const SentimentSalesListComponent = () => {
  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Sales</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Sales Management</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <img src={generateImageUrl('5156c5cb-dce6-4912-bea6-f4b26d5dd87d')} alt='sentiment' />
        </div>
      </KTCard>
    </>
  )
}

const SentimentSalesListWrapper = () => {
  return (
    <>
      <SentimentSalesListComponent />
    </>
  )
}

export default SentimentSalesListWrapper
