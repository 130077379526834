import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import TopProgramListWrapper from '../components/top-programs-list.component'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import TrendProgramListWrapper from '../components/trend-programs-list.component'
import PlatinumDonorListWrapper from '../components/platinum-donors-list.component'
import SalesProjectionListWrapper from '../components/sales-projections-list.component'
import SentimentSalesListWrapper from '../components/sentiment-sales-list-component'

const bisBreadcrumbs: Array<PageLink> = [
  {
    title: 'Business Intelligence',
    path: '/apps/bi-management/top-programs',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BiPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='top-programs'
          element={
            <>
              <PageTitle breadcrumbs={bisBreadcrumbs}>Winning Programs</PageTitle>
              <TopProgramListWrapper />
            </>
          }
        />
        <Route
          path='trend-programs'
          element={
            <>
              <PageTitle breadcrumbs={bisBreadcrumbs}>Trend Programs</PageTitle>
              <TrendProgramListWrapper />
            </>
          }
        />
        <Route
          path='platinum-donors'
          element={
            <>
              <PageTitle breadcrumbs={bisBreadcrumbs}>Platinum Donors</PageTitle>
              <PlatinumDonorListWrapper />
            </>
          }
        />
        <Route
          path='sales-projections'
          element={
            <>
              <PageTitle breadcrumbs={bisBreadcrumbs}>Projections</PageTitle>
              <SalesProjectionListWrapper />
            </>
          }
        />
        <Route
          path='sentiment-sales'
          element={
            <>
              <PageTitle breadcrumbs={bisBreadcrumbs}>Sentiment Sales</PageTitle>
              <SentimentSalesListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/apps/bi-management/top-programs' />} />
    </Routes>
  )
}

export default BiPage
