import {create} from 'zustand'
import {
  BITopProgramsItem,
  BITopProgramsResultInterface,
} from '../interfaces/bi-top-programs-result.interaface'
import {
  GetAllBiParams,
  GetAllBiPlatinumDonors,
  GetAllBiSalesProjections,
  GetAllBiTopPrograms,
  GetAllBiTrendPrograms,
} from '../providers/bi.provider'

interface BiState {
  biTopProgramLoading: boolean
  biTopPrograms: BITopProgramsItem[]
  biTop10Programs: BITopProgramsItem[]
  biTopProgramModel: BITopProgramsResultInterface
  getBiTopPrograms: (params: GetAllBiParams) => Promise<void>

  biTrendProgramLoading: boolean
  biTrendPrograms: BITopProgramsItem[]
  biTrend10Programs: BITopProgramsItem[]
  biTrendProgramModel: BITopProgramsResultInterface
  getBiTrendPrograms: (params: GetAllBiParams) => Promise<void>

  biPlatinumDonorLoading: boolean
  biPlatinumDonors: BITopProgramsItem[]
  biPlatinumDonorModel: BITopProgramsResultInterface
  getBiPlatinumDonors: (params: GetAllBiParams) => Promise<void>

  biSalesProjectionLoading: boolean
  biSalesProjections: BITopProgramsItem[]
  biSales10Projections: BITopProgramsItem[]
  biSalesProjectionModel: BITopProgramsResultInterface
  getBiSalesProjections: (params: GetAllBiParams) => Promise<void>
}

export const useBiStore = create<BiState>((set, get) => ({
  biTopProgramLoading: false,
  biTopProgramModel: undefined,
  biTopPrograms: [],
  biTop10Programs: [],
  getBiTopPrograms: async (params: GetAllBiParams) => {
    set({biTopProgramLoading: true})
    const response = await GetAllBiTopPrograms(params)
    set({biTopProgramModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        const top10programs = []
        response?.data?.items?.forEach((item, index) => {
          if (index < 10) {
            top10programs.push(item)
          }
        })
        set({biTopPrograms: response.data?.items, biTop10Programs: top10programs})
      }
    }
    set({biTopProgramLoading: false})
  },

  biTrendProgramLoading: false,
  biTrendProgramModel: undefined,
  biTrendPrograms: [],
  biTrend10Programs: [],
  getBiTrendPrograms: async (params: GetAllBiParams) => {
    set({biTrendProgramLoading: true})
    const response = await GetAllBiTrendPrograms(params)
    set({biTrendProgramModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        const trend10programs = []
        response?.data?.items?.forEach((item, index) => {
          if (index < 10) {
            trend10programs.push(item)
          }
        })
        set({biTrendPrograms: response.data?.items, biTrend10Programs: trend10programs})
      }
    }
    set({biTrendProgramLoading: false})
  },

  biPlatinumDonorLoading: false,
  biPlatinumDonorModel: undefined,
  biPlatinumDonors: [],
  getBiPlatinumDonors: async (params: GetAllBiParams) => {
    set({biPlatinumDonorLoading: true})
    const response = await GetAllBiPlatinumDonors(params)
    set({biPlatinumDonorModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        set({biPlatinumDonors: response.data?.items})
      }
    }
    set({biPlatinumDonorLoading: false})
  },

  biSalesProjectionLoading: false,
  biSalesProjections: [],
  biSales10Projections: [],
  biSalesProjectionModel: undefined,
  getBiSalesProjections: async (params: GetAllBiParams) => {
    set({biSalesProjectionLoading: true})
    const response = await GetAllBiSalesProjections(params)
    set({biSalesProjectionModel: response})
    if (response.status === true) {
      if (response.data?.items?.length > 0) {
        const sales10Projections = []
        response?.data?.items?.forEach((item, index) => {
          if (index < 10) {
            sales10Projections.push(item)
          }
        })
        set({biSalesProjections: response.data?.items, biSales10Projections: sales10Projections})
      }
    }
    set({biSalesProjectionLoading: false})
  },
}))
