import {useNavigate} from 'react-router-dom'
import {useBiStore} from '../states/bi.state'
import {useEffect} from 'react'
import {KTCard} from '../../../../_metronic/helpers'
import {LoadingComponent} from '../../apps/crm/properties-management/components/loading.component'
import ReactPaginate from 'react-paginate'
import {generateCurrencyFormat} from '../../../helpers/generate-currency-format'

const PlatinumDonorListComponent = () => {
  const {getBiPlatinumDonors, biPlatinumDonorLoading, biPlatinumDonorModel, biPlatinumDonors} =
    useBiStore()
  const navigate = useNavigate()

  useEffect(() => {
    getBiPlatinumDonors({page: 0, size: 10})
  }, [])

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected
    getBiPlatinumDonors({page: selectedPage, size: 10})
  }

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Platinum Donors</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Platinum Donors Management</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {biPlatinumDonorLoading ? <LoadingComponent /> : <></>}
            <table className='table table-row-bordered table-striped table-hover table-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Customer Name</th>
                  <th>Company Name</th>
                  <th>Total Donates</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {biPlatinumDonors.length === 0 ? (
                  <tr>
                    <td colSpan={6}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  biPlatinumDonors?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.customer_name}</td>
                        <td>{item.company_name}</td>
                        <td>{item.total_donates}</td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>

          <div className='card-footer'>
            <ReactPaginate
              previousLabel={'«'}
              nextLabel={'»'}
              breakLabel={'...'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageCount={biPlatinumDonorModel?.data?.total_pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={handlePageClick}
              containerClassName={'pagination pagination-sm m-0 float-right'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </KTCard>
    </>
  )
}

const PlatinumDonorListWrapper = () => {
  return (
    <>
      <PlatinumDonorListComponent />
    </>
  )
}

export default PlatinumDonorListWrapper
