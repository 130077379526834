import {AxiosError} from 'axios'
import {ApiProgram} from '../../../api/api'
import {BITopProgramsResultInterface} from '../interfaces/bi-top-programs-result.interaface'

export interface GetAllBiParams {
  page?: number
  size?: number
}

export const GetAllBiTopPrograms = async (
  params: GetAllBiParams
): Promise<BITopProgramsResultInterface> => {
  try {
    let response = await ApiProgram.get('/bi-top-programs', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: BITopProgramsResultInterface = a.response?.data
    return response
  }
}

export const GetAllBiTrendPrograms = async (
  params: GetAllBiParams
): Promise<BITopProgramsResultInterface> => {
  try {
    let response = await ApiProgram.get('/bi-trend-programs', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: BITopProgramsResultInterface = a.response?.data
    return response
  }
}

export const GetAllBiPlatinumDonors = async (
  params: GetAllBiParams
): Promise<BITopProgramsResultInterface> => {
  try {
    let response = await ApiProgram.get('/bi-platinum-donors', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: BITopProgramsResultInterface = a.response?.data
    return response
  }
}

export const GetAllBiSalesProjections = async (
  params: GetAllBiParams
): Promise<BITopProgramsResultInterface> => {
  try {
    let response = await ApiProgram.get('/bi-sales-projections', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: BITopProgramsResultInterface = a.response?.data
    return response
  }
}
